import { Location, navigate } from '@reach/router';
import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import SiteLayout from '../components/SiteLayout';

import useSWR from 'swr';

// @ts-ignore
import Terms from '../../static/terms.inline.svg';

// @ts-ignore
import { backbutton, button, image } from './velg.module.scss';

import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from 'gatsby';
import { ConsentStatus, SessionStatus, ValidationStatus } from "../service";
import { useTrackedSessionStoreStore } from "../state/sessionStore";

export function VelgComponent({ isEmbeded }) {
    const [loadingBankId, setLoadingBankId] = useState<boolean>(false);
    const [loadingVipps, setLoadingVipps] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);

    const sessionStore = useTrackedSessionStoreStore();

    const { data, error } = useSWR(() => ['checkSession', sessionStore.sessionId], async (url, sessionId) => {
        sessionStore.check({ sessionId });
    },
        { revalidateOnReconnect: true, revalidateOnFocus: true }
    );

    useEffect(() => {

        let embeded = "";
        isEmbeded ? embeded = "/embeded/" : embeded = "/";

        if (sessionStore.sessionStatus.sessionStatuses === SessionStatus.NUMBER_2) {
            navigate(embeded + 'feil?sid=' + sessionStore.sessionId);
        }

        if (sessionStore.sessionStatus.consentStatuses === ConsentStatus.NUMBER_2 && sessionStore.sessionStatus.validationStatus === ValidationStatus.NUMBER_1) {
            navigate(embeded + 'status?sid=' + sessionStore.sessionId);
        }

    }, [sessionStore.sessionStatus]);

    async function startSession(type) {

        setDisabled(true);
        if (type === 2) { setLoadingBankId(true); }
        if (type === 3) { setLoadingVipps(true); }
        const request = await sessionStore.start({ startRequest: { authMethod: type, sessionId: sessionStore.sessionId, isEmbeded } });
        setLoadingBankId(false);
        setLoadingVipps(false);
        setDisabled(false);
        if (request.authUrl) {
            window.open(request.authUrl);
            /*if (isEmbeded) {
                window.open(process.env.GATSBY_AKTIVERHANWEB_URL + "/embeded/identifiser?sid=" + sessionStore.sessionId);
                navigate('/embeded/status?sid=' + sessionStore.sessionId);
            } else {
                navigate('/identifiser?sid=' + sessionStore.sessionId);
            }*/
        }
    }

    return (
        <>
            {sessionStore.authMethods?.bankIdMobile ?
                <Button type="primary" htmlType="submit" size="large" disabled={disabled} loading={loadingBankId} className={button} onClick={() => startSession(2)}>
                    BANKID
                </Button>
                : null}

            <br />

            {sessionStore.authMethods?.vipps ?
                <Button type="primary" htmlType="submit" size="large" disabled={disabled} loading={loadingVipps} className={button} onClick={() => startSession(3)}>
                    VIPPS
                </Button>
                : null}
            <br />
        </>
    );
}

function Velg({ props }) {

    return (<Location>
        {({ location, navigate }) => (
            <SiteLayout location={location}>
                <Terms className={image} />
                <br />
                <h1>Aktiver HAN</h1>
                <p style={{ fontWeight: 400 }}>Velg hvilken metode du vil benytte</p>
                <br />
                <VelgComponent isEmbeded={false} />
                <Link to="/"><Button icon={<ArrowLeftOutlined />} className={backbutton}>Tilbake</Button></Link>
            </SiteLayout>
        )}
    </Location>);
}

export default Velg;
